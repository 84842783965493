@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.column {
  display: block;
  box-sizing: border-box;

  @for $i from 1 through 12 {
    &--#{$i} {
      flex: none;
      width: percentage($i / 12);
    }
  }

  @for $i from 1 through 12 {
    &--offset-#{$i} {
      margin-left: percentage($i / 12);
    }
  }

  @include mobile {
    @for $i from 1 through 12 {
      &--#{$i}-mobile-only {
        flex: none;
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      &--offset-#{$i}-mobile-only {
        margin-left: percentage($i / 12);
      }
    }
  }

  @include touch {
    @for $i from 1 through 12 {
      &--#{$i}-touch-only {
        flex: none;
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      &--offset-#{$i}-touch-only {
        margin-left: percentage($i / 12);
      }
    }
  }

  @include tablet-only {
    @for $i from 1 through 12 {
      &--#{$i}-tablet-only {
        flex: none;
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      &--offset-#{$i}-tablet-only {
        margin-left: percentage($i / 12);
      }
    }
  }

  @include tablet {
    @for $i from 1 through 12 {
      &--#{$i}-tablet-up {
        flex: none;
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      &--offset-#{$i}-tablet-up {
        margin-left: percentage($i / 12);
      }
    }
  }

  @include desktop {
    @for $i from 1 through 12 {
      &--#{$i}-desktop-only {
        flex: none;
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      &--offset-#{$i}-desktop-only {
        margin-left: percentage($i / 12);
      }
    }
  }
}
