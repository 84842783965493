@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.header {
  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 0;
    text-align: center;

    svg {
      width: 100%;
      max-height: 3.5rem;

      :global(.curryenvaninkel_svg__stroke) {
        fill: $black;
      }

      :global(.curryenvaninkel_svg__inner) {
        fill: $primary;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $black;
    }
  }

  &__hamburger {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      margin-right: 0.75rem;
      width: 1.5rem;
      max-height: 1.5rem;
      fill: $black;
      cursor: pointer;
    }
  }
}

@include mobile {
  .header {
    &__nav {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background: $white;
      transform: translateY(-100%);
      transition: transform 0.3s;
      align-items: flex-start;
      flex-direction: column;

      &--open {
        transform: translateX(0);
      }
    }
  }
}
