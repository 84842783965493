@import '../../assets/scss/variables';

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;

  &__title {
    font-family: 'Oswald', sans-serif;
    letter-spacing: 0;
    font-weight: 400;
    margin: 1rem 0;
  }

  &__paragraph {
    max-width: 30rem;
    margin: 0 0 2rem 0;
  }

  &__form {
    display: flex;
    width: 100%;
    max-width: 30rem;
  }

  &__error {
    color: $danger;
  }

  &__success {
    color: $success;
  }
}
