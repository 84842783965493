@import '../../assets/scss/variables';

.memo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  &__image {
    box-shadow: 1px 1px 5px $black;
  }
}
