@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

@include touch {
  .row {
    &--touchReverse {
      flex-direction: column-reverse;
    }
  }
}
