@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.hero {
  position: relative;
  background: $black;
  margin-bottom: 4rem;

  &--small {
    height: 300px;
  }

  &--medium {
    height: 400px;
  }

  &--large {
    height: 500px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    z-index: 0;
    max-width: $desktop;
    margin: 0 auto;

    &--fullscreen {
      max-width: unset;
    }

    &--cover {
      background-size: cover;
    }

    &--contain {
      background-size: contain;
    }

    &--left {
      background-position: left;
    }

    &--center {
      background-position: center;
    }

    &--right {
      background-position: right;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 1;
  }

  &__label {
    justify-self: center;
    font-size: 3rem;
    font-family: 'Oswald', sans-serif;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    text-shadow: 0 0 4px $black;
  }
}

@include mobile {
  .hero {
    // Temporary fix
    &--large {
      height: 700px;
    }

    &__label {
      font-size: 2rem;
    }
  }
}
