@import '../../assets/scss/variables';

.poweredby {
  text-align: center;

  &__text {
    font-size: 0.75rem;
    margin: 2rem 0 0 0;
  }

  svg {
    height: 1.5rem;
    width: 6rem;
  }
}
