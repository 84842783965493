@import '../../assets/scss/variables';

.spinner {
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 0.75rem);
    left: calc(50% - 0.75rem);
    width: 3rem;
    height: 3rem;
    border: 2px solid $black;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(1turn);
  }
}
