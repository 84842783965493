@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.podcast {
  margin-bottom: 4rem;

  &__image {
    &__container {
      padding-right: 1rem;
    }
  }

  &__content {
    padding: 0 1rem;
  }

  &__meta {
    font-size: 1.25rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.5;

    span {
      &:not(:first-child)::before {
        content: '\002f\002f';
        margin: 0 0.5rem;
        color: $light-grey;
      }
    }
  }

  &__title {
    font-size: 1.5rem;
    font-family: 'Oswald', sans-serif;
    line-height: 3;
  }
}

@include mobile {
  .podcast {
    &__image {
      &__container {
        display: flex;
        justify-content: center;
      }
    }

    &__meta {
      font-size: 0.85rem;
    }

    &__title {
      line-height: 2;
    }
  }
}
