$tablet: 800px;
$desktop: 1140px;

$primary: #58938e;
$white: #fff;
$black: #313131;
$light-grey: #d8d8d8;
$grey: #999999;
$green: #5ebd3e;
$dark-green: #41882a;

$danger: #bd0000;
$warning: #ffdd57;
$success: #21c45a;
