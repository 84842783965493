.image {
  position: relative;
  width: 100%;
  height: 100%;

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
  }
}
