@import '../../assets/scss/variables';

.button {
  position: relative;
  color: $white;
  border-radius: 4px;
  border: 3px solid $black;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s, background 0.3s;
  margin: 0;

  &:hover {
    color: $black;
    background: $white;
  }

  &--black {
    background: $black;
    border-color: $black;
  }

  &--primary {
    background: $primary;
    border-color: $primary;
  }

  &--disabled {
    background: $grey;
    pointer-events: none;
  }

  &--flatten {
    &-left {
      border-radius: 0 4px 4px 0;
    }

    &-right {
      border-radius: 4px 0 0 4px;
    }
  }

  &--loading {
    color: $black;
    pointer-events: none;

    &:hover {
      background: $black;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 0.75rem);
      left: calc(50% - 0.75rem);
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid $white;
      border-right-color: transparent;
      border-radius: 50%;
      animation: spin 0.5s linear infinite;
    }
  }

  &__container {
    display: flex;

    &--centered {
      justify-content: center;
    }
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(1turn);
  }
}
