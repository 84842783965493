@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.social {
  position: relative;
  color: $white;
  background: $primary;
  border: 3px solid $primary;
  border-radius: 4px;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s, background 0.3s;
  margin: 0 1rem 1rem 0;
  white-space: nowrap;

  &:hover {
    background: transparent;
  }

  a {
    display: flex;
    align-items: center;
    color: $white;
    text-decoration: none;

    svg {
      max-width: 1.5rem;
      height: 1.5rem;
      fill: $white;
      transition: fill 0.3s;
      margin-right: 1rem;
    }
  }
}

@include mobile {
  .social {
    padding: 0.5rem 1rem;
  }
}
