@import '../../assets/scss/variables';

.title {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  width: 100%;
  height: 55px;
  margin: 0 0 2rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid $light-grey;

  &--centered {
    text-align: center;
  }

  span {
    position: relative;

    span {
      color: $primary;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      border-bottom: 2px solid $primary;
    }
  }
}
