@import '../../assets/scss/variables';

.input {
  flex: 1 1 auto;
  border-radius: 4px;
  border: 2px solid $light-grey;
  font-size: 1rem;
  font-family: inherit;
  padding: 1rem;
  appearance: none;
  margin: 0;
  width: 100%;

  &--flatten {
    &-left {
      border-left: transparent;
      border-radius: 0 4px 4px 0;
    }

    &-right {
      border-right: transparent;
      border-radius: 4px 0 0 4px;
    }
  }
}
