@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.navitem {
  display: inline-block;
  margin: 2.5rem 1rem;

  &__link {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    color: $black;
    transition: color 0.3s;

    &--active {
      color: $primary;
    }

    &:hover {
      color: $grey;
    }
  }
}

@include mobile {
  .navitem {
    display: block;
    margin: 1.5rem;
  }
}
