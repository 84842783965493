@import './mixins';
@import './variables';

@include mobile {
  .t--mobile-hidden {
    display: none !important;
  }
}

@include tablet-only {
  .t--tablet-hidden {
    display: none !important;
  }
}

@include touch {
  .t--touch-hidden {
    display: none !important;
  }
}

@include tablet {
  .t--tablet-up-hidden {
    display: none !important;
  }
}

@include desktop {
  .t--desktop-hidden {
    display: none !important;
  }
}
