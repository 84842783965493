.transition__slidein {
  transition: opacity 0.5s, transform 0.5s linear;

  &--start {
    opacity: 0;
    transform: translateY(2rem);
  }
}

.transition__slidetop {
  transition: opacity 0.5s, transform 0.5s linear;

  &--start {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

.transition__slideleft {
  transition: opacity 0.5s, transform 0.5s linear;

  &--start {
    opacity: 0;
    transform: translateX(-1rem);
  }
}

.transition__fade {
  transition: opacity 0.3s;

  &--start {
    opacity: 0;
  }
}
