@import '../../assets/scss/variables';

:global {
  .green-audio-player {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    svg,
    img {
      display: block;
    }

    .play-pause-btn {
      display: none;
      cursor: pointer;
      padding: 0.75rem;
      border-radius: 4px;
      transition: background 0.3s;

      svg {
        width: 1rem;
        height: 1rem;

        path {
          fill: $white;
        }
      }
    }

    .loading {
      padding: 0.75rem;
      border-radius: 4px;
      background: $black;

      .loading__spinner {
        width: 1rem;
        height: 1rem;
        border: 2px solid $white;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spin 0.4s linear infinite;
      }
    }

    .slider {
      flex-grow: 1;
      background-color: $light-grey;
      cursor: pointer;
      position: relative;

      .gap-progress {
        background-color: $primary;
        border-radius: inherit;
        position: absolute;
        pointer-events: none;
        transition: width 0.2s, height 0.2s;

        .pin {
          height: 16px;
          width: 16px;
          border-radius: 8px;
          background-color: transparent;
          position: absolute;
          pointer-events: all;
        }
      }
    }

    .controls {
      font-family: 'Karla', sans-serif;
      font-size: 0.8rem;
      line-height: 1.5rem;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      margin-left: 24px;
      margin-right: 24px;

      &__current-time,
      &__total-time {
        width: 1.5rem;
      }

      &__slider {
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 2px;
        height: 10px;
      }

      &__progress {
        width: 0;
        height: 100%;

        .progress__pin {
          right: -8px;
          top: -6px;
        }
      }

      span {
        cursor: default;
      }
    }

    .volume {
      position: relative;

      .volume__button {
        padding: 0.75rem;
        border-radius: 4px;
        transition: background 0.3s;
        cursor: pointer;

        svg {
          width: 1rem;
          height: 1rem;

          path {
            fill: $white;
          }
        }
      }

      .volume__controls {
        position: absolute;
        left: 3px;
        bottom: 52px;
        display: flex;
        width: 2rem;
        height: 10rem;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        z-index: 2;

        &.hidden {
          display: none;
        }

        &.top {
          bottom: 3rem;
        }

        &.middle {
          bottom: -150%;
          left: 3rem;
        }

        &.bottom {
          bottom: -10.5rem;
        }

        .volume__slider {
          margin-top: 12px;
          margin-bottom: 12px;
          width: 6px;
          border-radius: 3px;

          .volume__progress {
            bottom: 0;
            height: 100%;
            width: 6px;

            .volume__pin {
              left: -5px;
              top: -8px;
            }
          }
        }
      }
    }

    &--black {
      .play-pause-btn {
        background: $black;

        &:hover {
          background: $primary;
        }

        svg path {
          fill: $white;
        }
      }

      .controls {
        color: $black;
      }

      .volume__button {
        background: $black;

        &:hover {
          background: $primary;
        }
      }

      .volume__controls {
        background-color: $black;
      }
    }

    &--white {
      .play-pause-btn {
        background: $primary;

        &:hover {
          background: $primary;
        }

        svg path {
          fill: $white;
        }
      }

      .controls {
        color: $white;
      }

      .volume__button {
        background: $black;

        &:hover {
          background: $black;
        }
      }

      .volume__controls {
        background-color: $white;
      }
    }
  }
}

@keyframes :global(spin) {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(1turn);
  }
}
