@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.featured {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__label {
    color: $grey;
    font-size: 1.25rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 1rem 0;
  }

  &__title {
    color: $white;
    font-size: 2.5rem;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    margin: 0.5rem 0;
  }

  &__description {
    color: $grey;
    margin: 0;
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__container {
      width: 200px;
      height: 200px;
    }
  }
}

@include mobile {
  .featured {
    flex-direction: column-reverse;
    &__title {
      text-align: center;
      font-size: 1.75rem;
    }

    &__image {
      justify-content: center;
    }
  }
}
