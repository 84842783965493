.podcast__image {
  position: relative;
  width: 100%;
  margin: 0 1rem 1rem 0;
  z-index: 1;
  border-radius: 0.25rem;
  max-width: 350px;

  &__first {
    border-radius: 4px;
    overflow: hidden;
  }

  &__second {
    position: absolute;
    top: 0.75rem;
    left: calc(50% - (95% / 2));
    width: 95%;
    border-radius: 0.25rem;
    overflow: hidden;
    opacity: 0.6;
    z-index: -1;
    transition: top 0.3s ease-in-out;
  }

  &__third {
    position: absolute;
    top: 1.5rem;
    left: calc(50% - (90% / 2));
    width: 90%;
    border-radius: 0.25rem;
    overflow: hidden;
    z-index: -2;
    opacity: 0.2;
    transition: top 0.3s ease-in-out;
  }

  &:hover {
    .podcast__image__second {
      top: 0rem;
    }

    .podcast__image__third {
      top: 0rem;
    }
  }
}
