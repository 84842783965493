@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin font {
  font-family: 'Karla', sans-serif;
}

@mixin horizontalRangeInput($trackColor, $thumbColor) {
  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      background: $trackColor;
    }

    &::-ms-fill-lower {
      background: $trackColor;
    }
    &::-ms-fill-upper {
      background: $trackColor;
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.15em;
    cursor: pointer;
    box-shadow: none;
    background: $trackColor;
    border-radius: 0;
    border: none;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: none;
    height: 1em;
    width: 1em;
    border-radius: 0;
    background: $thumbColor;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.4em;
  }

  &::-moz-range-track {
    width: 100%;
    height: 0.15em;
    cursor: pointer;
    box-shadow: none;
    background: $trackColor;
    border-radius: 0;
    border: none;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: none;
    height: 1em;
    width: 1em;
    border-radius: 0;
    background: $thumbColor;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 0.25em;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 0.25em 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: $trackColor;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  &::-ms-fill-upper {
    background: $trackColor;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  &::-ms-thumb {
    box-shadow: none;
    border: none;
    height: 0.75em;
    width: 0.75em;
    border-radius: 0;
    background: $thumbColor;
    cursor: pointer;
    margin-top: 0.1em;
  }
}
